.index-section {
  padding-top: calc(35/375*100)vw;
  padding-bottom: calc(30/375*100)vw;

  &-inner {
    @mixin m-innersp;

  }

  &-title {
    text-align: center;
    font-family: var(--font-apex-medium);
    font-size: calc(32/375*100)vw;
    font-weight: normal;
    letter-spacing: .05em;

  }
}


.index-news {

  &-plus {
    margin-top: calc(30/375*100)vw;
    display:none;
    &-content {
      margin-top: calc(20/375*100)vw;
      position: relative;

      &-link {
        display: block;
      }

      &-tags {
        list-style: none;
        padding-right: 23%;

        &-tag {
          font-family: var(--font-apex-medium);
          font-size: calc(9/375*100)vw;
          border: 1px solid var(--text-primary);
          border-radius: calc(3/375*100)vw;
          display: inline-block;
          padding: calc(5/375*100)vw calc(10/375*100)vw calc(1/375*100)vw;
          margin-top: calc(5/375*100)vw;
          margin-right: calc(2/375*100)vw;
          letter-spacing: .1em;
          text-transform: uppercase;
        }
      }
      &-text {
        font-family: var(--font-tsukushi);
        font-feature-settings: "palt" 1;
        line-height: 1.375;
        margin-top: calc(5/375*100)vw;
      }

      &-date {
        font-family: var(--font-apex-book);
        font-size: calc(12/375*100)vw;
        color: var(--text-heading);
        margin-top: calc(12/375*100)vw;
        position: absolute;
        top: 0;
        right: 0;
        letter-spacing: .1em;
      }
    }
  }

  &-morebtn {
    @mixin m-more-btn-large-hover var(--text-secondary), var(--footer-text-hover), var(--color-primary);
    width: calc(267/375*100)vw;

    span {
      font-family: var(--font-apex-medium);
      letter-spacing: .1em;
    }

    &-cover {
      text-align: center;
      margin-top: calc(45/375*100)vw;
    }
  }
}

.index-models {
  margin-top: calc(30/375*100)vw;

  &-model {
    &-link {
      display: block;
      position: relative;
    }

    &-image {
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 0;
      padding-top: 62%;

      &-quadorifoglio {
        background-image: url(../image/top-model-image-quadrifoglio-2204-mobile@2x.jpg);
      }
      &-stelvio {
        background-image: url(../image/top-model-image-stelvio-2203-mobile@2x.jpg);
      }
      &-giulia {
        background-image: url(../image/top-model-image-giulia-2204-mobile@2x.jpg);
      }
      &-giulietta {
        background-image: url(../image/top-model-image-giulietta-mobile-2108@2x.jpg);
      }
      &-4c {
        background-image: url(../image/top-model-image-4c-mobile@2x.jpg);
      }
      &-4cspider {
        background-image: url(../image/top-model-image-4cspider-mobile@2x.jpg);
      }

    }

    &-text {
      padding: calc(20/375*100)vw;
      color: var(--button-text-hover);
      position: absolute;
      bottom: 0;
      left: 0;

      &-type {
        font-size: calc(12/375*100)vw;

      }

      &-model {
        font-size: calc(24/375*100)vw;
        font-family: var(--font-apex-medium);
        margin-top: calc(5/375*100)vw;
        letter-spacing: .05em;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .new {
          width: 100%;
          line-height: 1.2;
          display: block;
          &:after {
            content: "new";
            text-transform: uppercase;
            color: var(--color-primary);
            font-size: 76%;
          }
        }
      }

      &-catch {
        display: none;
      }

      &-spec {
        display: none;
      }

      &-discon {
        font-size: calc(12/375*100)vw;
        margin-left: calc(15/375*100)vw;
        letter-spacing: 0;
      }

      &-btn {
        @mixin m-more-btn-small-hover var(--button-text-hover) , transparent;
        font-size: calc(12/375*100)vw;
        width: calc(130/375*100)vw;
        text-align: left;
        margin-top: calc(5/375*100)vw;

        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }

        i {
          right: 15%;
        }

      }
    }
  }
}

.index-movies {

  &-morebtn {
    @mixin m-more-btn-large-hover var(--text-secondary), var(--footer-text-hover), var(--color-primary);
    width: calc(267/375*100)vw;
		&.index-movies-morebtn-xl {
		width: 100%;
		padding-right: 1em;
		}
    span {
      font-family: var(--font-apex-medium);
      letter-spacing: .1em;
    }

    &-cover {
      text-align: center;
      margin-top: calc(30/375*100)vw;
    }
  }

}
.index-section-gallery {
  display: none;
}

.index-information {
	&-pcbanner{
		  display: none!important;
	  }
  &-emergency {
    &-cover {
      background: var(--bgcolor-secondary);

    }
    &-inner {
      padding: calc(40/375*100)vw calc(20/375*100)vw;
    }
    &-title {
      text-align: center;
      line-height: 1.3125;
    }
    &-text {
      text-align: center;
      line-height: 1.375;
      margin-top: calc(27/375*100)vw;
    }

    &-link {
      margin: calc(40/375*100)vw calc(-5/375*100)vw 0;

      a {
        color: var(--color-primary);
      }
    }
  }

  &-covid19 {
    margin: calc(38/375*100)vw calc(20/375*100)vw calc(10/375*100)vw;

    &-button {
      @mixin m-more-btn-large-hover;
      width: 100%;
    }
  }
}

.index-kvcarousel{
	.u-carousel-kv-catch{
		font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
	}
	.u-carousel-kv-lead{
		font-family:"Yu Gothic", "游ゴシック", YuGothic, "游ゴシック体", "ヒラギノ角ゴ Pro W3", "メイリオ", sans-serif;
	}
}
