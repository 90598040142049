
  @mixin m-middle {


    .index-section {
      padding-top: 80px;
      padding-bottom: 80px;

      &-inner {
        @mixin m-inner;

      }

      &-title {
        font-size: 4.2rem;

      }
    }


    .index-news {

      &-plus {
        border-top: 1px solid var(--line-primary);
        margin-top: 60px;
        padding: 0 30px 0;
        display:block;
        &-content {
          margin-top: 30px;

          &-link {
            @mixin m-flexset;
            color: var(--text-primary);
          }

          &-tags {
            list-style: none;
            padding-right: 0;
            margin-top: 5px;
            @mixin m-flexbase 200px;

            &-tag {
              font-size: .9rem;
              border-radius: 3px;
              padding: 5px 10px 1px;
              margin-top: 5px;
              margin-right: 2px;
            }
          }
          &-text {
            margin-top: 5px;
            font-size: 1.8rem;
            flex: 1;
            padding-right: 130px;

            a:hover & {
              color: var(--color-primary);
              text-decoration-line: underline;
              text-decoration-color: var(--color-primary);
            }
          }

          &-date {
            font-size: 1.2rem;
            margin-top: 10px;

          }
        }
      }

      &-morebtn {
        @mixin m-more-btn-large var(--text-secondary) , var(--button-text-hover)  , var(--color-primary);
        width: 267px;
        span {
          font-family: var(--font-apex-book);
          letter-spacing: .1em;
        }

        &-cover {
          text-align: center;
          margin-top: 80px;
        }
      }
    }

  .index-models {
    margin-top: 30px;
    @mixin m-flexset space-between, wrap;

    &-model {
      @mixin m-flexbase 33.33%;
      &-link {
        display: block;
        position: relative;
      }

      &-image {
        line-height: 0;
        position: relative;
        width: 100%;
        height: 62%;
        overflow: hidden;

        &-quadorifoglio {
          background-image: url(../image/top-model-image-quadrifoglio-2204@2x.jpg);
        }
        &-stelvio {
          background-image: url(../image/top-model-image-stelvio-2203@2x.jpg);
        }
        &-giulia {
          background-image: url(../image/top-model-image-giulia-2204@2x.jpg);
        }
        &-giulietta {
          background-image: url(../image/top-model-image-giulietta-2108@2x.jpg);
        }
        &-4c {
          background-image: url(../image/top-model-image-4c@2x.jpg);
        }
        &-4cspider {
          background-image: url(../image/top-model-image-4cspider@2x.jpg);
        }

        &::before {
          content: '';
          height: 100%;
          width: 0;
          background-color: var(--color-hoverbg);
          transition: width var(--base-animespeed) var(--animate-easeinout);
          mix-blend-mode: multiply;
          position: absolute;
          top:0;
          left:0;
          a:hover & {
            width: 100%;
          }
        }

        img {
          width: 100%;
          height: auto;
          transition: opacity var(--base-animespeed);
        }
      }

      &-text {
        padding: 20px;

        &-type {
          font-size: 1.2rem;

        }

        &-model {
          font-size: 2.4rem;
          margin-top: 10px;
          .new:after {
            a:hover & {
              color: #fff;
            }
          }
        }

        &-catch {
          display: none;
          line-height: 1.375;
          margin-top: 10px;

          a:hover & {
            display: block;

          }
        }

        &-discon {
          font-size: 1.2rem;
          margin-left: 15px;

        }

        &-spec {
          display: none;
          line-height: 1.375;
          margin-top: 10px;
          font-size: .9rem;

          sup {
            font-size: .9rem;
            vertical-align: super;
          }

          &-number {
            font-size: 2.8rem;
            font-size: 28px;
            font-family: var(--font-apex-book);
          }
          &-unit {
            font-size: 1.4rem;
            font-family: var(--font-apex-book);
          }

          &-bar {
            display: inline-block;
            width: 1px;
            background-color: var(--button-text-hover);
            height: 28px;
            margin: 0 10px;
          }



          a:hover & {
            display: block;

          }
        }
        &-note {
          font-size: .9rem;
        }

        &-btn {
          @mixin m-more-btn-small var(--button-text-hover) , var(--color-primary), var(--button-text-hover);
          font-size: 1.2rem;
          width: 140px;
          margin-top: 5px;

          span {
            font-family: var(--font-apex-book);
            letter-spacing: .1em;
          }

        }
      }
    }
  }

  .index-movies {

    &-morebtn {
      @mixin m-more-btn-large var(--text-secondary) , var(--button-text-hover)  , var(--color-primary);
      width: 267px;
		&.index-movies-morebtn-xl {
			width: 380px;
		}
      span {
        font-family: var(--font-apex-medium);
        letter-spacing: .1em;
      }

      &-cover {
        text-align: center;
        margin-top: 80px;
      }
    }

  }

  .index-section-gallery {
    display: block;

    .index-section-title {
      display: none;
    }
  }

  .index-gallery {

    &-item {
      width: 490px;
      margin-bottom: 20px;
      line-height: 0;

      a {
        display: block;
        position: relative;
        overflow: hidden;

        &:hover {

          img {
            transform: scale(1.1,1.1);
          }
        }
      }

      img {
        transition: transform var(--base-animespeed) var(--animate-easeinout);
        width: 100%;
        height: auto;
      }

      &-s {
        width: 235px;
      }
    }

  }

  .index-information {
	  &-pcbanner{
		  max-height:56%;
		  width:auto;
		  max-width:350px;
		  margin-bottom:26%;
		  display: block!important;
	  }

    .pc-hide {
      display: none;
    }

    &-emergency {
      &-cover {

      }
      &-inner {
        @mixin m-inner;
        padding: 40px 0;
      }
      &-title {
        font-size: 2rem;
      }
      &-text {
        margin-top: 30px;

      }

      &-link {
        text-align: center;
        margin-top: 40px;


        a {
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    &-covid19 {
      margin: 40px 0 0;
      text-align: center;

      &-button {
        @mixin m-more-btn-large;
        width: 745px;

        br {
          display: none;
        }
      }
    }
  }


}
